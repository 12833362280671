.separator {
    display: flex;
    flex-direction: row;
  }
  .separator:before, .separator:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  .separator:before {
    margin-right: 10px
  }
  .separator:after {
    margin-left: 10px
  }